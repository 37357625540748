import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Loading from '../components/Loading'

const NotFoundPage = () => {
    const [content, setContent] = useState(<Loading color='black.main' />)
    setTimeout(() => {
        setContent('Sorry, Page not found.')
    }, 10000);
    return (
        <Grid alignItems='center' container={true} justifyContent='center' sx={{ display: 'flex' }} height='100vh' width='100%'>
            <Grid item>
                {content}
            </Grid>
        </Grid>
    )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>

